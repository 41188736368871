// Packages
import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Components
import Button from "~components/common/Button";
import H3 from "~components/typography/H3";
import P from "~components/typography/P";

// Types
import type { IGatsbyImageData } from "gatsby-plugin-image";

interface Props {
	title: string;
	description: string;
	to?: string;
	imageData: IGatsbyImageData;
	buttonText: string;
	alt: string;
}

const Card: React.FC<Props> = ({ title, description, to, imageData, alt, buttonText }) => {
	const image = getImage(imageData);
	return (
		<div>
			{image ? (
				<>
					{to ? (
						<Link to={to}>
							<GatsbyImage image={image} className="mb-6 rounded" alt={alt} title={alt} />
						</Link>
					) : (
						<GatsbyImage image={image} className="mb-6 cursor-not-allowed rounded" alt={alt} title={alt} />
					)}
				</>
			) : null}

			<H3 as="p" className="mb-4">
				{title}
			</H3>
			<P className="mb-2">{description}</P>

			<Button text={buttonText} type={to ? "secondary" : "disabled"} to={to ? to : undefined} className="px-0" />
		</div>
	);
};

export default Card;
