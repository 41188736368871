// Packages
import React from "react";
import { graphql } from "gatsby";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";
import type { Product } from "~types/Shopify";
import type { IGatsbyImageData } from "gatsby-plugin-image";

// Components
import H2 from "~components/typography/H2";
import Card from "~components/cards/Card";
import Grid from "~components/common/Grid";
import Alert from "~components/common/Alert";
import ProductCard from "~components/shop/ProductCard";
import Container from "~components/common/Container";
import PageHeader from "~components/sections/PageHeader";
import Layout from "~components/layouts/Layout";
import SubTitle from "~components/typography/SubTitle";
import P from "~components/typography/P";
import Ul from "~components/typography/Ul";
import Button from "~components/common/Button";
import slugify from "slugify";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	products: {
		nodes: Product[];
		distinct: string[];
	};
	imageWorkshopSpeedReading: IGatsbyImageData;
	imageWorkshopYourOwnMoneyStrategy: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	return (
		<Layout
			location={location}
			title="Workshops"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<PageHeader
				subtitle="Workshops"
				title="Unsere Kurse"
				description="Mit unseren Workshops kannst Du gezielt neue Techniken lernen und Deine Talente stärken."
			/>
			<Container className="space-y-8">
				<Grid cols={2}>
					<Card
						title="Speed Reading Workshops"
						to="/workshops/speed-reading/"
						imageData={data.imageWorkshopSpeedReading}
						description="Schneller lesen, mehr behalten und verstehen. Viele unserer Kursteilnehmer verdoppeln ihre Lesegeschwindigkeit innerhalb weniger Tage und haben diese Fähigkeit dann für den Rest ihres Lebens"
						buttonText="Zum Speed Reading Workshop"
						alt="Speed reading Workshops"
					/>
					{/* <div>
						<SubTitle className="mb-2">Shopkategorien</SubTitle>
						<H2 className="mb-4">Weitere Angebote</H2>
						{categories.map((category) => (
							<Button to={`/workshops/${slugify(category, { lower: true })}`} key={category} className="mb-2 w-full" type="secondary">
								{category}
							</Button>
						))}
					</div> */}
					{/* <Card
						title="Your own Money Strategy"
						imageData={data.imageWorkshopYourOwnMoneyStrategy}
						description="Lerne die Möglichkeit der Vermögenden kennen und entwickle Deine eigene Strategie. Viele unserer Kursteilnehmer verdoppeln ihre Lesegeschwindigkeit innerhalb weniger Tage und haben diese	Fähigkeit dann für den Rest ihres Lebens."
						buttonText="Coming soon!"
						alt="Your own Money Strategy Workshops"
					/> */}
				</Grid>
			</Container>

			<Container>
				<SubTitle className="mb-2">Angebot</SubTitle>

				<H2 className="mb-4">Was sind Workshops?</H2>
				<Grid cols={2}>
					<div>
						<P className="mb-4">Du bist Student oder auf dem Weg Karriere zu machen?</P>
						<Ul
							items={[
								"Du willst wissen warum andere Kommilitonen schneller studieren?",
								"Du fragst Dich warum die Elite schneller und mit besseren Leistungen studiert?",
								"Deine Regelstudienzeit wird immer knapper?",
								"Beim Lernen fehlt dir oft die Motivation und der Fokus?",
								"Durch den Leistungsdruck, fühlst Dich immer mehr unter Druck und bist gestresst?",
							]}
							pretty
						/>
						<P className="mb-4">
							So geht es Tausenden von Studenten…allerdings kennen nur wenige die Geheimnisse für den Erfolg im Studium.
							Dir fehlt die Weiterentwicklung Deiner kognitiven Fähigkeiten und die richtigen Methoden.
						</P>
						<P className="mb-4">
							Lerne die Methode des Speedreadings. Mehrfach schneller Lesen und gleichzeitig mehrfaches komplexeres
							Wissen behalten und verstehen.
						</P>
						<P>LEADERS ARE READERS und WISSEN IST MACHT</P>
					</div>
					<div>
						<P className="mb-4">In meinem Workshop: lernst Du alle Techniken, Methoden und Erfolgsgeheimnisse um</P>
						<Ul
							items={["Schneller", "Effektiver", "mit besseren Ergebnissen", "in weniger Zeit"]}
							pretty
							className="mb-4"
						/>
						<P>…Dein Studium zu erledigen. Durch meine Workshops studieren Studenten 2-3 Semester weniger</P>
					</div>
				</Grid>
			</Container>

			{/* <Container id="workshops">
				<div className="text-center">
					<SubTitle className="mb-2">Andere Produkte</SubTitle>
					<H2 display className="mb-16">
						Weitere Kategorien
					</H2>
				</div>

				{products.nodes.length > 0 ? (
					<Grid cols={3}>
						{products.nodes.map((product) => (
							<ProductCard key={product.title} product={product} />
						))}
					</Grid>
				) : (
					<Alert type="information" title="Keine Workshops gefunden" message={`Aktuell sind wir mitten in der Kursplanung. Komme gerne später wieder oder buche unseren Newsletter für weitere Informationen.`} />
				)}
			</Container> */}
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		imageWorkshopSpeedReading: file(relativePath: { eq: "pages/workshops/workshops_speed-reading.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		imageWorkshopYourOwnMoneyStrategy: file(
			relativePath: { eq: "pages/workshops/workshops_your-own-money-strategy.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
